import { useLocation, Navigate } from "react-router-dom";
import api from "./../api";

export const setToken = (token) => {
  localStorage.setItem("authToken", token);
};

export const fetchToken = () => {
  return localStorage.getItem("authToken");
};

export function RequireToken({ children }) {
  let auth = fetchToken();
  let location = useLocation();

  if (!auth) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
}

export function removeToken() {
  localStorage.removeItem("authToken");
}

export function Logout() {
  localStorage.removeItem("authToken");
  window.location.href = "/";
}

export async function fetchCompanyNumber() {
  const token = fetchToken();
  try {
    const response = await api
      .get("/company/number", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((error) => {
        console.log("Error fetching company number. " + error);
      });
    const company_number = await response.data.company_number;
    return company_number;
  } catch (error) {
    console.log("Error fetching company number. " + error);
  }
}

export async function fetchUserName() {
  const token = fetchToken();
  try {
    const response = await api
      .get("/user/name", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((error) => {
        console.log("Error fetching user name. " + error);
      });
    const name = await response.data.name;
    return name;
  } catch (error) {
    console.log("Error fetching user name. " + error);
  }
}
