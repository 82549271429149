import { useParams } from "react-router";

import { setToken } from "./Auth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import api from "./../api";

const Signup = () => {
  const { authToken } = useParams();
  setToken(authToken);
  const navigate = useNavigate();

  useEffect(() => {
    api.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
    api.get("/company/number").then((response) => {
      if (response.data.company_number === 0) {
        navigate("/addcompany");
      } else {
        navigate("/company/dashboard");
      }
    });
  }, [authToken, navigate]);

  return null;
};

export default Signup;
