import { Grid, TextField, Button, Alert, AlertTitle } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./../App.css";
import api from "./../api";
import { fetchToken, fetchCompanyNumber } from "../components/Auth";

const AddCompany = (props) => {
  const [fakturanr, setFakturanr] = useState("");
  const [kundernr, setKundernr] = useState("");
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [helperTextFakturanr, setHelperTextFakturanr] = React.useState("");
  const [helperTextKundernr, setHelperTextKundernr] = React.useState("");
  const savedTheme = localStorage.getItem("theme");
  const oppenedFrom = useState(
    props.oppenedFrom !== undefined ? props.oppenedFrom : "Defoult"
  );

  const navigate = useNavigate();

  const handleFakturanrChange = (event) => {
    setFakturanr(event.target.value);
    if (event.target.value !== "") {
      setHelperTextFakturanr("");
    }
  };
  const handleKundernrChange = (event) => {
    setKundernr(event.target.value);
    if (event.target.value !== "") {
      setHelperTextKundernr("");
    }
  };

  const [companyNumber, setCompanyNumber] = useState(0);
  const logo_img =
    savedTheme === "dark" ? "/images/logo-white.png" : "/images/logo-dark.png";

  useEffect(() => {
    const getData = async () => {
      try {
        const fetchedData = await fetchCompanyNumber();
        setCompanyNumber(fetchedData);
        if (fetchedData > 0) {
          window.location.href = "/company/dashboard";
        }
      } catch (err) {
        console.log("Error fetching company number. " + err);
      }
    };
    if (props.oppenedFrom !== "Dashboard") {
      getData();
    }
  }, [companyNumber, props.oppenedFrom]);

  const registerCompany = async () => {
    if (fakturanr === "") {
      setHelperTextFakturanr("Fakturanr. is required");
      return;
    }
    if (kundernr === "") {
      setHelperTextKundernr("Kundernr. is required");
      return;
    }
    const data = {
      fakturanr: fakturanr,
      kundernr: kundernr,
      jwttoken: fetchToken(),
    };
    await api
      .post("/company/add", data)
      .then((response) => {
        setAlert(false);
        if (oppenedFrom[0] === "Dashboard") {
          console.log("Company added");
          props.addedCompany();
        } else {
          navigate("/company/dashboard");
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setAlert(true);
          setAlertContent("Unauthorized");
        } else if (error.response.status === 409) {
          setAlert(true);
          setAlertContent("Company already exists");
        } else if (error.response.status === 400) {
          setAlert(true);
          setAlertContent("Invoice and customer number did not match");
        } else {
          setAlert(true);
          setAlertContent("Error");
        }
      });
  };

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      paddingTop={oppenedFrom[0] === "Defoult" ? "0" : "5vh"}
      justifyContent={oppenedFrom[0] === "Defoult" ? "center" : "flex-start"}
      sx={{ minHeight: "80vh" }}
    >
      <img src={logo_img} alt="Braathe logo" style={{ width: 400 }} />
      {alert ? (
        <Alert severity="error" variant="outlined">
          <AlertTitle>Error</AlertTitle>
          {alertContent}
        </Alert>
      ) : (
        <></>
      )}
      {oppenedFrom[0] === "Defoult" ? (
        <>
          <Grid item xs={12}>
            <h2>Company bill info</h2>
          </Grid>
          <Grid item xs={12}>
            <a href="/images/invoice_example.png">
              Please check PDF example for filling the form
            </a>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <h2>Add Company</h2>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <TextField
          required
          error={helperTextFakturanr !== ""}
          id="fakturanr-new"
          label="Fakturanr"
          variant="outlined"
          type="number"
          onChange={handleFakturanrChange}
          helperText={helperTextFakturanr}
          InputProps={{ inputProps: { min: 1 } }}
          className="addcompany-input"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          error={helperTextKundernr !== ""}
          id="kundernr-new"
          label="Kundenr"
          variant="outlined"
          type="number"
          onChange={handleKundernrChange}
          helperText={helperTextKundernr}
          InputProps={{ inputProps: { min: 1 } }}
          className="addcompany-input"
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          size="large"
          className="addcompany-input"
          onClick={registerCompany}
        >
          Register Company
        </Button>
      </Grid>
    </Grid>
  );
};

export default AddCompany;
