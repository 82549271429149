import React, { useEffect, useState } from "react";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme } from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import "./App.css";

import Theme from "./components/Theme";
import {
  RequireToken,
  Logout,
  fetchToken,
  fetchUserName,
} from "./components/Auth";
import Signup from "./components/Signup";

import Login from "./pages/Login";
import AddCompany from "./pages/AddCompany";
import Dashboard from "./pages/Dashboard";

const App = () => {
  const [themeType, setThemeType] = useState(
    createTheme({
      palette: {
        mode: "dark",
      },
    })
  );
  const getThemeType = (themeData) => {
    setThemeType(themeData);
  };
  const savedTheme = localStorage.getItem("theme");
  const logo_img =
    savedTheme === "dark" ? "/images/logo-white.png" : "/images/logo-dark.png";
  const token = fetchToken();
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const getData = async () => {
      try {
        const fetchedData = await fetchUserName();
        setUserName("Hi, " + fetchedData);
      } catch (err) {
        console.log("Error fetching user name. " + err);
      }
    };
    getData();
  }, []);

  const [companyName, setCompanyName] = useState("");
  const handleCompanyNameChange = (name) => {
    setCompanyName(name);
  };

  return (
    <ThemeProvider theme={themeType}>
      <CssBaseline />
      <Grid container spacing={2}>
        <Grid item xs={9}>
          {companyName ? (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={logo_img}
                  alt="Braathe logo"
                  style={{ width: 200, marginLeft: 30, marginRight: 200 }}
                />
                <h2
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  {companyName}
                </h2>
              </div>
            </>
          ) : null}
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            display: "flex",
            justifyContent: "right",
            marginBlockEnd: "0.83em",
            marginBlockStart: "0.83em",
          }}
        >
          <Theme setThemeTypeParent={getThemeType} />
          {token && userName ? (
            <div style={{ paddingTop: "11px" }}>
              {userName}
              <Button
                variant="text"
                color="secondary"
                startIcon={<LogoutIcon />}
                onClick={() => {
                  Logout();
                }}
              ></Button>
            </div>
          ) : null}
        </Grid>
      </Grid>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup/:authToken/" element={<Signup />} />
          <Route
            path="/addcompany"
            element={
              <RequireToken>
                <AddCompany />
              </RequireToken>
            }
          />
          <Route
            path="/company/dashboard"
            element={
              <RequireToken>
                <Dashboard updateCompanyHeader={handleCompanyNameChange} />
              </RequireToken>
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
