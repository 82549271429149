import React, { useState, useEffect } from "react";
import "./../App.css";
import api from "./../api";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Divider,
  LinearProgress,
  Drawer,
} from "@mui/material";

import { fetchToken, removeToken } from "../components/Auth";
import AddCompany from "./AddCompany";

const Dashboard = (props) => {
  const [invoices, setInvoices] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [companyNumber, setCompanyNumber] = useState(0);
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  const fetchInvoices = async () => {
    const token = fetchToken();
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    setLoading(true);
    try {
      await api
        .get("/company/invoices")
        .then((response) => {
          if (response.status === 200) {
            setInvoices(response.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            removeToken();
            document.location.href = "/";
          } else {
            alert("Error " + error.response.status);
            removeToken();
            document.location.href = "/";
          }
        });
    } catch (error) {
      alert("Error " + error);
    }
  };

  const getCompanyData = async () => {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${fetchToken()}`;
      api
        .get(`/company/data`)
        .then((response) => {
          props.updateCompanyHeader(response.data.name);
          setCompanyNumber(response.data.number);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            removeToken();
            document.location.href = "/";
          } else {
            document.location.href = "/";
            console.log("Error fetching company number. " + error);
          }
        });
    } catch (err) {
      console.log("Error fetching company number. " + err);
    }
  };
  const getCompanyList = async () => {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${fetchToken()}`;
      api
        .get(`/company/list`)
        .then((response) => {
          setCompanyList(response.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            removeToken();
            document.location.href = "/";
          } else {
            document.location.href = "/";
            console.log("Error fetching company list. " + error);
          }
        });
    } catch (err) {
      console.log("Error fetching company list. " + err);
    }
  };

  useEffect(() => {
    getCompanyData();
    fetchInvoices();
    getCompanyList(); // eslint-disable-next-line
  }, [props]);

  const handleAddedCompany = () => {
    setOpenDrawer(false);
    getCompanyData();
    fetchInvoices();
    getCompanyList();
  };

  const handleCompanyChange = (event) => {
    setLoading(true);
    api.defaults.headers.common["Authorization"] = `Bearer ${fetchToken()}`;
    try {
      api
        .post(`/company/set/active/${event.target.value}`)
        .then(() => {
          setCompanyNumber(event.target.value);
          getCompanyData();
          fetchInvoices();
        })
        .catch((error) => {
          if (error.response.status === 401) {
            removeToken();
            document.location.href = "/";
          } else {
            alert("Error " + error.response.status);
          }
        });
    } catch (error) {
      console.log("Error handle company change. " + error);
    }
  };

  const getPDF = async (referenceNumber) => {
    setLoading(true);
    const token = fetchToken();
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    try {
      await api
        .get(`/company/invoices/${referenceNumber}/pdf`, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${referenceNumber}.pdf`);
          document.body.appendChild(link);
          link.click();
          setLoading(false);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            removeToken();
            document.location.href = "/";
          } else {
            alert("Error " + error.response.status);
          }
        });
    } catch (error) {
      alert("Error " + error);
    }
  };

  const renderDetailsButton = (params) => {
    return (
      <Button
        variant="text"
        color="primary"
        size="small"
        style={{ textTransform: "none" }}
        onClick={() => {
          getPDF(params.row.referenceNumber);
        }}
      >
        PDF
      </Button>
    );
  };
  const columns = [
    { field: "referenceNumber", headerName: "Reference Number", width: 100 },
    {
      field: "Download",
      headerName: "Download",
      filterable: false,
      sortable: false,
      renderCell: renderDetailsButton,
    },
    { field: "status", headerName: "Status", width: 100 },
    {
      field: "balance",
      headerName: "Balance",
      width: 100,
      align: "right",
    },
    { field: "documentDate", headerName: "Document Date", width: 120 },
    { field: "documentDueDate", headerName: "Document Due Date", width: 120 },
    {
      field: "detailTotal",
      headerName: "Subtotal",
      width: 100,
      align: "right",
    },
    { field: "vatTotal", headerName: "Vat Total", width: 100, align: "right" },
    { field: "amount", headerName: "Amount", width: 100, align: "right" },
    { field: "documentType", headerName: "Document Type", width: 100 },
    { field: "paymentReference", headerName: "Payment Reference", width: 170 },
    {
      field: "externalReference",
      headerName: "External Reference",
      width: 150,
    },
    {
      field: "customerRefNumber",
      headerName: "Customer Ref Number",
      width: 150,
    },
    { field: "invoiceText", headerName: "Invoice Text", width: 200 },
    { field: "customer_number", headerName: "Customer Number", width: 100 },
    {
      field: "paymentMethod_description",
      headerName: "Payment Method Description",
      width: 200,
    },
  ];

  return (
    <>
      <Divider />
      {loading && <LinearProgress />}
      <div style={{ marginLeft: "20px", marginRight: "20px" }}>
        {companyList.length > 1 && (
          <FormControl variant="standard" sx={{ m: 1, minWidth: 250 }}>
            <InputLabel id="company-list-label">Select Company</InputLabel>
            <Select
              id="company-list"
              onChange={handleCompanyChange}
              value={companyNumber}
              autoWidth
              label="Select Company"
            >
              {companyList.map((company) => (
                <MenuItem key={company.number} value={company.number}>
                  {company.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Button onClick={toggleDrawer(true)} style={{ marginTop: "20px" }}>
          Add company
        </Button>
        <Drawer
          onClose={toggleDrawer(false)}
          open={openDrawer}
          anchor={"right"}
        >
          <div style={{ width: "400px" }}>
            <AddCompany
              oppenedFrom="Dashboard"
              addedCompany={handleAddedCompany}
            />
          </div>
        </Drawer>
        <Divider />
        <DataGrid
          rows={invoices}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5, 10, 15, 30]}
          disableRowSelectionOnClick
          sx={{ "&, [class^=MuiDataGrid]": { border: "none" } }}
          slots={{ toolbar: GridToolbar }}
        />
      </div>
    </>
  );
};

export default Dashboard;
