import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";

import { fetchCompanyNumber } from "./../components/Auth";

const Login = () => {
  let URL = process.env.REACT_APP_API_BASE_URL;
  if (URL === "/") URL = "https://hub.braathe.dev/";
  const savedTheme = localStorage.getItem("theme");
  const redirect_to_google = () => {
    window.location.href = `${URL}/login/google`;
  };

  const redirect_to_microsoft = () => {
    window.location.href = `${URL}/login/microsoft`;
  };

  const [companyNumber, setCompanyNumber] = useState(0);

  useEffect(() => {
    const getData = async () => {
      try {
        const fetchedData = await fetchCompanyNumber();
        setCompanyNumber(fetchedData);
        if (fetchedData > 0) {
          window.location.href = "/company/dashboard";
        } else if (fetchedData === 0) {
          window.location.href = "/addcompany";
        }
      } catch (err) {
        console.log("Error fetching company number. " + err);
      }
    };
    getData();
  }, [companyNumber]);

  const logo_img =
    savedTheme === "dark" ? "/images/logo-white.png" : "/images/logo-dark.png";

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "80vh" }}
    >
      <img src={logo_img} alt="Braathe logo" style={{ width: 400 }} />
      <Grid item xs={12}>
        <h2>Login to Verity - Visma portal</h2>
      </Grid>
      <Grid item xs={12}>
        <Box
          border="2px solid grey"
          p={2}
          width={400}
          display="flex"
          flexDirection="row"
          alignItems="center"
          onClick={redirect_to_google}
          style={{ cursor: "pointer" }}
        >
          <img
            src="https://img.icons8.com/color/48/000000/google-logo.png"
            alt="Google logo"
          />
          <span style={{ marginLeft: "10px" }}>Continue with Google</span>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          border="2px solid grey"
          p={2}
          width={400}
          display="flex"
          flexDirection="row"
          alignItems="center"
          onClick={redirect_to_microsoft}
          style={{ cursor: "pointer" }}
        >
          <img
            src="https://img.icons8.com/color/48/000000/microsoft.png"
            alt="Microsoft logo"
          />
          <span style={{ marginLeft: "10px" }}>Continue with Microsoft</span>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
